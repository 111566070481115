import client from './client';
import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'constants'

export function getPhoto(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/photos/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getPhotos(limit = 20, page = 0, search = null, filter = {}, order, selectedPersonIds) {
  const token = Cookies.get(TOKEN)
  const params = { limit, offset: page * limit, search, ...filter, order, selectedPersonIds }

  return client.get(`${API_URL}/api/v1/photos.json`, { params, headers: { Authorization: token }})
    .then(response => response.data)
}

export function createPhoto(params, config = {}) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/photos.json`, params, { headers: { Authorization: token }, ...config })
    .then(response => response.data.data)
}

export function updatePhoto(id, params) {
  const token = Cookies.get(TOKEN)

  return client.put(`${API_URL}/api/v1/photos/${id}.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)

}

export function deletePhoto(id) {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/api/v1/photos/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}
