import { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { MessageContext } from 'App'
import { PeopleForm } from 'components'
import { getPerson, updatePerson } from 'api'

const Container = styled.div`
`

const PeopleEditPage = () => {
  const { showSuccess, showError } = useContext(MessageContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const [person, setPerson] = useState(null)
  const [searchParams, _] = useSearchParams()

  const onChangeByName = (name) => (e) => setPerson({ ...person, [name]: e.target?.value})

  useEffect(() => { getPerson(id).then(setPerson) }, [id])

  const onUpdate = () => {
    const formData = new FormData()

    formData.append('person[last_name]', person?.last_name || '')
    formData.append('person[first_name]', person?.first_name || '')
    if (person?.born_date) formData.append('person[born]', person?.born_date)
    if (person?.died_date) formData.append('person[died]', person?.died_date)
    if (person?.gender) formData.append('person[gender]', person?.gender)
    if (person?.father_id) formData.append('person[father_id]', person?.father_id)
    if (person?.mother_id) formData.append('person[mother_id]', person?.mother_id)
    if (person?.location) formData.append('person[location]', person?.location)
    if (person?.comment) formData.append('person[comment]', person?.comment)
    if (person?.file) formData.append('person[file]', person?.file)

    updatePerson(person?.id, formData).then((person) => {
      showSuccess('Чалавек пасьпяхова адрэдагаваны')

      setPerson(person)

      navigate(searchParams.get('redirect_path') || `/people/${id}`)
    }).catch((respose) => {
      showError()
    })
  }

  return (
    <Container>
      <h2>Рэдагаваньне</h2>
      <PeopleForm person={person} onChangeByName={onChangeByName} onSumbit={onUpdate}/>
    </Container>
  )
}

export default PeopleEditPage
