import { useEffect, useState, useMemo, useRef, useCallback, useContext } from 'react'
import styled from 'styled-components'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'
import cx from 'classnames'

import { Event } from 'components'
import { MessageContext } from 'App'
import { getEvents, acceptEvent, declineEvent, deleteEvent } from 'api'

const Container = styled.div`
`

const EventsPage = () => {
  const [events, setEvents] = useState([])
  const { showSuccess, showError } = useContext(MessageContext)

  useEffect(() => { getEvents().then(setEvents) }, [])

  const onEventDelete = (id) => {
    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць падзею?`)) {
      deleteEvent(id).then((response) => {
        showSuccess('Падзея пасьпяхова выдалена')

        setEvents([
          ...events.filter(({ id }) => id != response.id)
        ])
      }).catch((respose) => {
        showError()
      })
    }
  }

  const onEventAccept = (id) => {
    acceptEvent(id).then((event) => {
      showSuccess('Запыт прыняты')

      const index = events.findIndex(({ attributes: { id: eventId }}) => eventId === id)

      setEvents([...events.slice(0, index), { attributes: event }, ...events.slice(index + 1, events.length)])
    }).catch((respose) => {
      showError()
    })
  }

  const onEventDecline = (id) => {
    declineEvent(id).then((event) => {
      showSuccess('Запыт адхілены')

      const index = events.findIndex(({ attributes: { id: eventId }}) => eventId === id)

      setEvents([...events.slice(0, index), { attributes: event }, ...events.slice(index + 1, events.length)])
    }).catch((respose) => {
      showError()
    })
  }

  return (
    <Container>
      <table className="table">
        <tbody>
          <tr>
            <th>Падзея</th>
            <th>Час</th>
            <th>Дзеяньні</th>
          </tr>
          {
            events.map((event, index) => <Event key={index} event={event} onEventDelete={onEventDelete} onEventAccept={onEventAccept} onEventDecline={onEventDecline}/>)
          }
        </tbody>
      </table>
    </Container>
  )
}

export default EventsPage
