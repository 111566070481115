import client from './client';
import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'constants'

export function getTag(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/tags/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getTags() {
  return client.get(`${API_URL}/api/v1/tags.json`, { withCredentials: true })
    .then(response => response.data.data)
}

export function createTag(params) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/tags.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function updateTag(id, params) {
  const token = Cookies.get(TOKEN)

  return client.put(`${API_URL}/api/v1/tags/${id}.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function deleteTag(id) {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/api/v1/tags/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}
