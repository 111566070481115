import { useEffect, useState, useRef, useContext } from 'react'
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import { MessageContext } from 'App'
import { getPerson, getPersonPhotos, deletePerson, createNote } from 'api'
import { TreePanel, Photos } from 'components'
import { formatInputDate } from 'helpers/utils'

const Container = styled('div')`
  .card-header {
    cursor: pointer;
  }
`

const PersonLink = ({ name, id }) => {
  return (
    <Link to={`/people/${id}`}>{name}</Link>
  )
}

const PeopleShowPage = () => {
  const navigate = useNavigate()
  const { showSuccess, showError } = useContext(MessageContext)
  const [searchParams, _] = useSearchParams()
  const { id } = useParams()
  const [person, setPerson] = useState(null)
  const [message, setMessage] = useState('')
  const closeModalButtonRef = useRef(null)
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    getPerson(id).then(setPerson).then(() => {
      getPersonPhotos(id).then(setPhotos)
    })
  }, [id])

  const closeModal = () => {
    closeModalButtonRef.current.click()
  }

  const onDelete = () => {
    if (window.confirm(`Вы упэўнены, што хочаце выдаліць: ${person.full_name}?`)) {
      deletePerson(person.id).then(() => {
        navigate(searchParams.get('redirect_path') || '/people')
      })
    }
  }

  const onCreateNote = () => {
    const params = {
      person_id: id,
      message
    }

    createNote(params).then((note) => {
      showSuccess('Заўвага пасьпяхова адпраўлена')
      closeModal()
      setMessage('')
    }).catch((respose) => {
      showError()
    })
  }

  return (
    <Container className="container">
      <div className="person" data-person-id="1">
        <div className="row">
            <div className="col-sm-11 col-md-2">
              <img className="img-fluid" src={person?.photo_url} alt={person?.first_name}/>
            </div>
            <div className="col-sm-1 col-md-10 text-center">
              <h2>{person?.full_name}</h2>
              {
                person?.born_date && <p><span>Дата нараджэньня:</span> <span>{formatInputDate(person?.born_date)}</span></p>
              }
              {
                person?.died_date && <p><span>Дата сьмерці:</span> <span>{formatInputDate(person?.died_date)}</span></p>
              }
              <p><span>Мясціны жыцьця:</span> <span>{person?.location}</span></p>
              <Link className="btn" to={`/home/${person?.id}`}><i className="fa fa-user"></i></Link>
              <a className="btn" data-toggle="modal" href="#tree-modal"><i className="fa fa-sitemap"></i></a>
              <Link className="btn" to={`/people/${person?.id}/edit`}><i className="fa fa-pencil"></i></Link>
              {/* <button usePrompt="Вы упэўнены?" className="btn" to={`/people/${person?.id}`}><i className="fa fa-user-times"></i></Link> */}
              <button className="btn" onClick={onDelete}><i className="fa fa-user-times"></i></button>
            </div>
        </div>
        <div id="accordion">
          <div className="card">
            <div aria-controls="collapsePerson" aria-expanded="true" className="card-header" data-target="#collapsePerson" data-toggle="collapse" id="headingPerson">
                <h4>Сям'я</h4>
            </div>
            <div aria-labelledby="headingPerson" className="collapse" id="collapsePerson">
                <div className="card-body">
                  <h5>Бацькі</h5>
                  <ul>
                      {
                        person?.father && (
                          <li>
                            <span>Бацька: </span>
                            <PersonLink id={person?.father?.id} name={person?.father?.full_name}/>
                          </li>
                        )
                      }
                      {
                        person?.mother && (
                          <li>
                            <span>Маці: </span>
                            <PersonLink id={person?.mother?.id} name={person?.mother?.full_name}/>
                          </li>
                        )
                      }
                  </ul>
                  {
                    person?._children.length ? (
                      <>
                        <h5>Дзеці</h5>
                        <ul>
                          {
                            person?._children.map(({ id, full_name }) => {
                              return <li key={id}><PersonLink id={id} name={full_name}/></li>
                            })
                          }
                        </ul>
                      </>
                    ) : ''
                  }
                  {
                    person?._siblings.length ? (
                      <>
                        <h5>Браты й сёстры</h5>
                        <ul>
                          {
                            person?._siblings.map(({ id, full_name }) => {
                              return <li key={id}><PersonLink id={id} name={full_name}/></li>
                            })
                          }
                        </ul>
                      </>
                    ) : ''
                  }
                  {/* <h5>Дзядулі й бабулі</h5>
                  <ul>
                      <li><a href="/people/5">Поплавский Евгений Федорович (16/05/1926)</a></li>
                      <li><a href="/people/8">Поплавская (Король) Ася Петровна (22/11/1931)</a></li>
                      <li><a href="/people/11">Пумпур Анатолий Викентьевич (13/10/1935)</a></li>
                      <li><a href="/people/12">Пумпур (Урбановіч) Леакадыя Браніславаўна (05/04/1939)</a></li>
                  </ul> */}
                </div>
            </div>
          </div>
          {
            person?.comment && (
              <div className="card">
                <div aria-controls="collapseComment" aria-expanded="true" className="card-header" data-target="#collapseComment" data-toggle="collapse" id="headingComment">
                  <h4>Біяграфія</h4>
                </div>
                <div aria-labelledby="headingComment" className="collapse" id="collapseComment">
                  <div className="card-body">
                    {person?.comment.split("\n").map((line, index) => (<p key={index}>{line}</p>))}
                  </div>
                </div>
              </div>
            )
          }
          {
            <div className="card">
              <div aria-controls="collapsePhoto" aria-expanded="true" className="card-header" data-target="#collapsePhoto" data-toggle="collapse" id="headingPhoto">
                <h4>Фотаздымкі</h4>
              </div>
              <div aria-labelledby="headingPhoto" className="collapse" id="collapsePhoto">
                <div className='card-body'>
                  <Photos id={id} photos={photos}/>
                </div>
              </div>
            </div>
          }
        </div>
        <br/>
        {
          person?.created_by && (
            <>
              <div className="text-center">
                <p>
                  <b>Аўтар: </b>
                  <i>{person?.created_by?.full_name}</i>
                  <a className="btn" data-toggle="modal" href="#add-note"><i className="fa fa-envelope"></i></a>
                </p>
              </div>
            </>
          )
        }
        <TreePanel person={person}/>
        <div aria-hidden="true" aria-labelledby="myModalLabel" className="modal fade" id="add-note" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header d-block">
                <button ref={closeModalButtonRef} aria-label="Close" className="close float-right" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button><button aria-label="Close" className="close float-left" data-dismiss="modal" type="button"><span aria-hidden="true">&nbsp;</span></button>
                <h5 className="modal-title text-center">Заўвага</h5>
              </div>
              <div className="modal-body text-center">
                <div className="actions">
                  <div>
                    <div className="form-group text required note_message">
                      <textarea className="form-control text required" value={message} onChange={(e) => setMessage(e.target.value)}>
                      </textarea>
                    </div>
                    <button type="submit" className="btn btn-default" data-disable-with="Адправіць" onClick={onCreateNote}>Адправіць</button>
                  </div>
                </div>
                <div className="spinner justify-content-center" style={{display: 'none'}}>
                  <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PeopleShowPage
