const Textarea = (props) => {
  const { id, label, errors = [] } = props

  return (
    <div className="form-group text optional form-group-valid">
      {
        label && (
          <label className="form-control-label string optional" htmlFor={id}>
            {label}
          </label>
        )
      }
      <textarea
        className="form-control text optional"
        {...props}
      />
    </div>
  )
}

export default Textarea
