import { useState, useEffect, useCallback, useRef, Fragment, useContext } from 'react'
import LightGallery from 'lightgallery/react'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import fullScreen from 'lightgallery/plugins/fullscreen'
import styled from 'styled-components'
import Loader from './Loader'
import { getPhotos, deletePhoto } from 'api'
import { PHOTOS_LIMIT as limit } from 'constants'
import { MessageContext } from 'App'

import 'lightgallery/scss/lightgallery.scss'
import 'lightgallery/scss/lg-thumbnail.scss'
import 'lightgallery/scss/lg-fullscreen.scss'
import 'lightgallery/scss/lg-transitions.scss'

const Container = styled('div')`
  position: relative;

  .Photo {
    position: relative;
    display: inline-block;

    &:hover {

      .Photo-Action {
        opacity: 1;
      }
    }
  }

  .Photo-Action {
    opacity: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: opacity 0.2s;
  }

  .Image {
    width: 180px;
    height: 180px;
  }

  .Observer {
    border: 1px solid #000;
    position: absolute;
    bottom: 400px;
  }
`

const PhotoExplorer = ({ photos, setPhotos, search, filter, order, selectedPersonIds }) => {
  const { showSuccess, showError } = useContext(MessageContext)
  const [total, setTotal] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const observerTarget = useRef(null)

  const fetchData = useCallback(() => {
    if (!hasMore || isLoading) return

    setIsLoading(true)

    getPhotos(limit, page, search, filter, order, selectedPersonIds).then((photos) => {
      if (photos.data.length === 0) setHasMore(false)
      setPhotos((prevPhotos) => [...prevPhotos, ...photos.data])
      setTotal(photos.total)
      setPage((prevPage) => prevPage + 1)
      setIsLoading(false)
    })
  }, [search, page, setPhotos, isLoading, hasMore, filter, order, selectedPersonIds])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        fetchData()
      }
    })

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    // return () => {
    //   if (observerTarget.current) {
    //     observer.unobserve(observerTarget.current)
    //   }
    // }
  }, [fetchData])

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      await getPhotos(limit, 0, search, filter, order, selectedPersonIds).then((photos) => {
        setPhotos(photos.data)
        setTotal(photos.total)
      })
      setPage(1)
      setHasMore(true)
      setIsLoading(false)
    }

    getData()
  }, [search, filter, order, selectedPersonIds, setPhotos])

  const lgAfterSlide = useCallback(({ index }) => {
    const toolbar = document.getElementsByClassName('lg-toolbar')[0]
    let editButton = document.getElementById('lg-edit-button')

    if (!editButton) {
      const icon = document.createElement('i')
      icon.className = 'fa fa-pencil'

      const span = document.createElement('span')
      span.className = 'lg-icon'
      span.append(icon)

      editButton = Object.assign(document.createElement('a'), { id: 'lg-edit-button' })
      editButton.append(span)

      toolbar.append(editButton)
    }

    const photoId = document.getElementsByClassName('gallery-item')[index].dataset.id

    document.getElementById('lg-edit-button').setAttribute('href', `/photos/${photoId}/edit`)
  }, [])

  const onPhotoDelete = (event, id) => {
    event.stopPropagation()
    event.preventDefault()

    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць фотаздымак?`)) {
      deletePhoto(id).then((response) => {
        showSuccess('Фотаздымак пасьпяхова выдалены')

        setPhotos([
          ...photos.filter(({ id }) => id != response.id)
        ])
      }).catch((respose) => {
        showError()
      })
    }
  }

  return (
    <Container>
      <div>
        <span>Усяго: {total}</span>
      </div>
      <LightGallery onAfterSlide={lgAfterSlide} toggleThumb={true} allowMediaOverlap={true} plugins={[lgThumbnail, fullScreen]} mode='lg-lollipop'>
        {
          photos.map(({ attributes: { id, file: { url, thumb_url, large_url } }}, index) => {
            return (
              <Fragment key={index}>
                <a key={id}
                  data-id={id}
                  data-src={large_url}
                  data-sub-html={`.photo-${id}`}
                  className='Photo gallery-item'
                  data-download-url={url}
                >
                  <span className="Photo-Action" onClick={(e) => {onPhotoDelete(e, id) }}>
                    <i className="fa fa-times"></i>
                  </span>
                  <img className="Image img-responsive" id={id} src={thumb_url} alt={id}/>
                </a>
              </Fragment>
            )
          })
        }
      </LightGallery>
      {isLoading && <Loader/>}
      <div className='Observer' ref={observerTarget}></div>
    </Container>
  )
}

export default PhotoExplorer
