const TextField = (props) => {
  const { id, label, errors = [] } = props

  return (
    <div className="form-group string optional form-group-valid">
      {
        label && (
          <label className="form-control-label string optional" htmlFor={id}>
            {label}
          </label>
        )
      }
      <input
        className="form-control string optional"
        {...props}
      />
    </div>
  )
}

export default TextField
