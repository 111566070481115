import { useEffect } from 'react'
import styled from 'styled-components'
import * as d3 from 'd3'

const Container = styled('div')`
  svg {
    margin-top: 32px;
    border: 1px solid #aaa;
  }
  #tree-modal.modal {
    padding: 10px;
    svg {
      margin-top: 0;
    }
    .modal-dialog {
      height: 100%;
      width: 100%;
      max-width: none;
      margin: 0 auto;

      .modal-content {
        height: 100%;

        .modal-body {
          height: 100%;

          .close {
            position: absolute;
            right: 22px;
            top: 20px;
          }

          #tree {
            height: 100%;

            .link {
              fill: none;
              stroke: #ccc;
              stroke-width: 1.5px;
            }

            .person {
              rect {
                fill: #fff;
                stroke: steelblue;
                stroke-width: 1px;
              }
              font: 14px sans-serif;
              cursor: pointer;
              &[gender='man'] {
                rect {
                  stroke: steelblue;
                }
              }
              &[gender='woman'] {
                rect {
                  stroke: lightcoral;
                }
              }
              &[born] {
                font-weight: bold;
              }
              &[died] {
                rect {
                  fill: #eee;
                }
              }
            }
          }
        }
      }
    }
  }
`

const TreePanel = ({ person }) => {
  useEffect(() => {
    if (person) {
      var Tree, boxHeight, boxWidth, collapse, duration, elbow, nodeHeight, nodeWidth, rootProxy, separation, setup, transitionElbow, treeData;
      treeData = person;
      boxWidth = 300;
      boxHeight = 40;
      nodeWidth = 100;
      nodeHeight = 350;
      duration = 750;
      separation = .5;

      /**
       * For the sake of the examples, I want the setup code to be at the top.
       * However, since it uses a class (Tree) which is defined later, I wrap
       * the setup code in a function at call it at the end of the example.
       * Normally you would extract the entire Tree class defintion into a
       * separate file and include it before this script tag.
       */
      setup = function() {
        var ancestorRoot, ancestorTree, descendantRoot, descendantsTree, svg, zoom;
        zoom = d3.behavior.zoom().scaleExtent([.1, 1]).on('zoom', function() {
          svg.attr('transform', 'translate(' + d3.event.translate + ') scale(' + d3.event.scale + ')');
        }).translate([400, 200]);
        svg = d3.select('#tree').append('svg').attr('width', '100%').attr('height', '100%').call(zoom).append('g').attr('transform', 'translate(400,200)');
        ancestorTree = new Tree(svg, 'ancestor', 1);
        ancestorTree.children(function(person) {
          if (person.collapsed) {

          } else {
            return person._parents;
          }
        });
        descendantsTree = new Tree(svg, 'descendant', -1);
        descendantsTree.children(function(person) {
          if (person.collapsed) {

          } else {
            return person._children;
          }
        });
        ancestorRoot = rootProxy(treeData);
        descendantRoot = rootProxy(treeData);
        ancestorTree.data(ancestorRoot);
        descendantsTree.data(descendantRoot);
        ancestorTree.draw(ancestorRoot);
        descendantsTree.draw(descendantRoot);
      };
      rootProxy = function(root) {
        return {
          id: root.id,
          full_name: root.full_name,
          gender: root.gender,
          born: root.born,
          died: root.died,
          x0: 0,
          y0: 0,
          _children: root._children,
          _parents: root._parents,
          collapsed: false
        };
      };

      /**
       * Collapse person (hide their ancestors). We recursively
       * collapse the ancestors so that when the person is
       * expanded it will only reveal one generation. If we don't
       * recursively collapse the ancestors then when
       * the person is clicked on again to expand, all ancestors
       * that were previously showing will be shown again.
       * If you want that behavior then just remove the recursion
       * by removing the if block.
       */
      collapse = function(person) {
        person.collapsed = true;
        if (person._parents) {
          person._parents.forEach(collapse);
        }
        if (person._children) {
          person._children.forEach(collapse);
        }
      };

      /**
       * Custom path function that creates straight connecting
       * lines. Calculate start and end position of links.
       * Instead of drawing to the center of the node,
       * draw to the border of the person profile box.
       * That way drawing order doesn't matter. In other
       * words, if we draw to the center of the node
       * then we have to draw the links first and the
       * draw the boxes on top of them.
       */
      elbow = function(d, direction) {
        var sourceX, sourceY, targetX, targetY;
        sourceX = d.source.x;
        sourceY = d.source.y + boxWidth / 2;
        targetX = d.target.x;
        targetY = d.target.y - (boxWidth / 2);
        return 'M' + direction * sourceY + ',' + sourceX + 'H' + direction * (sourceY + (targetY - sourceY) / 2) + 'V' + targetX + 'H' + direction * targetY;
      };

      /**
       * Use a different elbow function for enter
       * and exit nodes. This is necessary because
       * the function above assumes that the nodes
       * are stationary along the x axis.
       */
      transitionElbow = function(d) {
        return 'M' + d.source.y + ',' + d.source.x + 'H' + d.source.y + 'V' + d.source.x + 'H' + d.source.y;
      };

      /**
       * Shared code for drawing ancestors or descendants.
       * `selector` is a class that will be applied to links
       * and nodes so that they can be queried later when
       * the tree is redrawn.
       * `direction` is either 1 (forward) or -1 (backward).
       */
      Tree = function(svg, selector, direction) {
        this.svg = svg;
        this.selector = selector;
        this.direction = direction;
        this.tree = d3.layout.tree().nodeSize([nodeWidth, nodeHeight]).separation(function() {
          return separation;
        });
      };

      /**
       * Set the `children` function for the tree
       */
      Tree.prototype.children = function(fn) {
        this.tree.children(fn);
        return this;
      };

      /**
       * Set the root of the tree
       */
      Tree.prototype.data = function(data) {
        this.root = data;
        return this;
      };

      /**
       * Draw/redraw the tree
       */
      Tree.prototype.draw = function(source) {
        var links, nodes;
        if (this.root) {
          nodes = this.tree.nodes(this.root);
          links = this.tree.links(nodes);
          this.drawLinks(links, source);
          this.drawNodes(nodes, source);
        } else {
          throw new Error('Missing root');
        }
        return this;
      };

      /**
       * Draw/redraw the connecting lines
       */
      Tree.prototype.drawLinks = function(links, source) {
        var link, self;
        self = this;
        link = self.svg.selectAll('path.link.' + self.selector).data(links, function(d) {
          return d.target.id;
        });
        link.enter().append('path').attr('class', 'link ' + self.selector).attr('d', function(d) {
          var o;
          o = {
            x: source.x0,
            y: self.direction * (source.y0 + boxWidth / 2)
          };
          return transitionElbow({
            source: o,
            target: o
          });
        });
        link.transition().duration(duration).attr('d', function(d) {
          return elbow(d, self.direction);
        });
        link.exit().transition().duration(duration).attr('d', function(d) {
          var o;
          o = {
            x: source.x,
            y: self.direction * (source.y + boxWidth / 2)
          };
          return transitionElbow({
            source: o,
            target: o
          });
        }).remove();
      };

      /**
       * Draw/redraw the person boxes.
       */
      Tree.prototype.drawNodes = function(nodes, source) {
        var node, nodeEnter, nodeExit, nodeUpdate, self;
        self = this;
        node = self.svg.selectAll('g.person.' + self.selector).data(nodes, function(person) {
          return person.id;
        });
        nodeEnter = node.enter().append('g').attr('class', 'person ' + self.selector).attr('gender', function(person) {
          return person.gender;
        }).attr('born', function(person) {
          return person.born;
        }).attr('died', function(person) {
          return person.died;
        }).attr('transform', function(person) {
          return 'translate(' + self.direction * (source.y0 + boxWidth / 2) + ',' + source.x0 + ')';
        }).on('click', function(person) {
          self.togglePerson(person);
        });
        nodeEnter.append('rect').attr({
          x: 0,
          y: 0,
          width: 0,
          height: 0
        });
        nodeEnter.append('text').attr('dx', 0).attr('dy', 0).attr('text-anchor', 'start').attr('class', 'name').text(function(d) {
          return d.full_name;
        }).style('fill-opacity', 0);
        nodeUpdate = node.transition().duration(duration).attr('transform', function(d) {
          return 'translate(' + self.direction * d.y + ',' + d.x + ')';
        });
        nodeUpdate.select('rect').attr({
          x: -(boxWidth / 2),
          y: -(boxHeight / 2),
          width: boxWidth,
          height: boxHeight
        });
        nodeUpdate.select('text').attr('dx', -(boxWidth / 2) + 10).style('fill-opacity', 1);
        nodeExit = node.exit().transition().duration(duration).attr('transform', function(d) {
          return 'translate(' + self.direction * (source.y + boxWidth / 2) + ',' + source.x + ')';
        }).remove();
        nodeExit.select('rect').attr({
          x: 0,
          y: 0,
          width: 0,
          height: 0
        });
        nodeExit.select('text').style('fill-opacity', 0).attr('dx', 0);
        nodes.forEach(function(person) {
          person.x0 = person.x;
          person.y0 = person.y;
        });
      };

      /**
       * Update a person's state when they are clicked.
       */
      Tree.prototype.togglePerson = function(person) {
        if (person === this.root) {
          return;
        } else {
          if (person.collapsed) {
            person.collapsed = false;
          } else {
            collapse(person);
          }
          this.draw(person);
        }
      };

      setup();
    }
  }, [person])

  return (
    <Container>
      <div aria-hidden="true" aria-labelledby="myModalLabel" className="modal fade" id="tree-modal" role="dialog" tabIndex="-1">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
              <div id="tree"></div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default TreePanel
