import client from './client';
import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'constants'

export function createRequestPermission(params) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/request_permissions.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function deleteRequestPermission(id) {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/api/v1/request_permissions/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}
