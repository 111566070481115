import { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { TextField, Textarea, Select, Select2, ImageField } from 'components'
import { getPeopleList } from 'api'
import { peopleToSelectOptions, formatInputDate } from 'helpers/utils'

const Container = styled.div`
  .preview, .photo {
    img {
      max-height: 200px;
    }
  }
`

const PeopleForm = ({ person, onSumbit, onChangeByName }) => {
  const [peopleList, setPeopleList] = useState([])
  const [preview, setPreview] = useState(null)

  const filterMan = ({ attributes: { gender } }) => (gender === 'man')
  const filterWoman = ({ attributes: { gender } }) => (gender === 'woman')

  const manOptions = useMemo(() => peopleList?.filter(filterMan).map(peopleToSelectOptions), [peopleList])
  const womanOptions = useMemo(() => peopleList?.filter(filterWoman).map(peopleToSelectOptions), [peopleList])
  const genderOptions = useMemo(() => [{ label: 'Мужчынскі', value: 'man' }, { label: 'Жаночы', value: 'woman' }], [])

  useEffect(() => { getPeopleList().then(setPeopleList) }, [])

  const handleOnUpload = (e) => {
    const file = e.target.files[0]

    const value = {}
    value['value'] = file

    const target = {}
    target['target'] = value

    onChangeByName('file')(target)

    const fileReader = new FileReader()

    fileReader.onload = function() {
      setPreview(fileReader.result)
    }

    fileReader.readAsDataURL(file)
  }

  const onChangeSelectByName = (name) => ({ value }) => {
    // debugger
    onChangeByName(name)({ target: { value }})
  }

  const genderValue = useMemo(() => {
    return genderOptions.find(({ value }) => value === person?.gender)
  }, [person?.gender, genderOptions])

  const fatherValue = useMemo(() => {
    return manOptions.find(({ value }) => value == person?.father_id)
  }, [person?.father_id, manOptions])

  const motherValue = useMemo(() => {
    return womanOptions.find(({ value }) => value == person?.mother_id)
  }, [person?.mother_id, womanOptions])

  return (
    <Container>
      <ImageField label={'Фота'} id={'photo'} value={person?.photo} onChange={handleOnUpload}/>
      {
        preview ? (
          <div className='preview'>
            <img src={preview} alt="Upload preview" />
          </div>
        ) : person?.photo_url && (
          <div className='photo'>
            <img src={person?.photo_url} alt="" />
          </div>
        )
      }
      <TextField label={'Прозьвішча'} id={'last_name'} value={person?.last_name || ''} onChange={onChangeByName('last_name')}/>
      <TextField label={'Імя'} id={'first_name'} value={person?.first_name || ''} onChange={onChangeByName('first_name')}/>
      {/* <TextField label={'Імя па бацьку'} id={'middle_name'} value={person?.middle_name || ''} onChange={onChangeByName('middle_name')}/> */}
      <TextField label={'Дата нараджэньня'} id={'born_date'} type='date' value={formatInputDate(person?.born_date) || ''} onChange={onChangeByName('born_date')}/>
      <TextField label={'Дата сьмерці'} id={'died_date'} type='date' value={formatInputDate(person?.died_date) || ''} onChange={onChangeByName('died_date')}/>
      <Select label={'Пол'} id={'gender'} emptyLabel value={genderValue} onChange={onChangeSelectByName('gender')} options={genderOptions}/>
      <Select label={'Бацька'} id={'father_id'} emptyLabel value={fatherValue} onChange={onChangeSelectByName('father_id')} options={manOptions}/>
      <Select label={'Маці'} id={'mother_id'} emptyLabel value={motherValue} onChange={onChangeSelectByName('mother_id')} options={womanOptions}/>
      <TextField label={'Мейсцы жыцьця'} id={'location'} value={person?.location || ''} onChange={onChangeByName('location')}/>
      <Textarea label={'Біяграфія'} id={'comment'} value={person?.comment || ''} onChange={onChangeByName('comment')} rows="10"/>
      <button className="btn btn btn-default" onClick={onSumbit}>Захаваць</button>
    </Container>
  )
}

export default PeopleForm
