import client from './client';
import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'constants'

export function getEvent(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/events/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getEvents() {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/events.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function createEvent(params) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/events.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function acceptEvent(id) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/events/${id}/accept.json`, null, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function declineEvent(id) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/events/${id}/decline.json`, null, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function updateEvent(id, params) {
  const token = Cookies.get(TOKEN)

  return client.put(`${API_URL}/api/v1/events/${id}.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function deleteEvent(id) {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/api/v1/events/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}
