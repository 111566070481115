import client from './client';
import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'constants'

export function getPerson(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/people/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getPersonShort(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/people/${id}/short.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getPersonPhotos(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/people/${id}/photos.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getPeople() {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/people.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getPeopleList() {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/people/list.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function createPerson(params) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/people.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function updatePerson(id, params) {
  const token = Cookies.get(TOKEN)

  return client.put(`${API_URL}/api/v1/people/${id}.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function deletePerson(id) {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/api/v1/people/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}
