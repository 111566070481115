import client from './client';
import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'constants'

export function getDocument(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/documents/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getDocuments() {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/documents.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function createDocument(params, config = {}) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/documents.json`, params, { headers: { Authorization: token }, ...config })
    .then(response => response.data.data)
}

export function updateDocument(id, params) {
  const token = Cookies.get(TOKEN)

  return client.put(`${API_URL}/api/v1/documents/${id}.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function deleteDocument(id) {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/api/v1/documents/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}
