import ReactSelect from 'react-select'

const Select = ({ id, label, emptyLabel, ...props }) => {
  const options = emptyLabel ? [{ label: '', value: '' }, ...(props.options || [])] : props.options

  return (
    <div className="form-group select required form-group-valid">
      {
        label && (
          <label className="form-control-label select required" htmlFor={id}>
            {label}
          </label>
        )
      }
      <ReactSelect {...props} options={options}/>
    </div>
  )
}

export default Select
