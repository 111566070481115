import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'
import cx from 'classnames'

import { Select, TextField, PhotoExplorer, FileUploader } from 'components'
import { getPermissions } from 'api'
import { peopleToSelectOptions } from 'helpers/utils'

const Container = styled.div`
`

const PermissionsPage = () => {
  const [permissions, setPermissions] = useState([])

  useEffect(() => { getPermissions().then(setPermissions) }, [])

  return (
    <Container>
      PERMISSIONS
      <div className='Permissions'>
        {
          permissions.map(({ id }) => {
            return <div>{id}</div>
          })
        }
      </div>
    </Container>
  )
}

export default PermissionsPage
