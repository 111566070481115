export const SET_CURRENT_USER = 'setCurrentUser'
export const SET_CSRF_TOKEN = 'setCSRFToken'
export const SET_PREVIOUS_PATH = 'setPreviousPath'
export const TOKEN = 'token'

export const loadingStatuses = {
  PENDING: 'loadingStatuses/pending',
  LOADED: 'loadingStatuses/loaded',
}

export const API_URL = process.env.REACT_APP_API_URL || ''

export const PUBLIC_PATHS = ['/login', '/signup']

export const PHOTOS_LIMIT = 30
