const ImageField = (props) => {
  const { id, label, errors = [] } = props

  return (
    <div className="form-group file optional form-group-valid">
      {
        label && (
          <label className="file optional" htmlFor={id}>
            {label}
          </label>
        )
      }
      <input
        className="form-control-file is-valid file optional"
        type="file"
        {...props}
      />
    </div>
  )
}

export default ImageField
