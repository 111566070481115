import { useEffect, useState, useRef, useMemo, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { setCurrentUser, setPreviousPath } from 'store/actions';
import { MessageContext } from 'App'
import { getPersonShort, getPeopleList, updatePerson, createRequestPermission } from 'api'
import { Select, PersonCard } from 'components'
import { peopleToSelectOptions } from 'helpers/utils'

const Container = styled('div')`
  padding-top: 16px;
  flex-direction: column;
  display: flex;
  gap: 32px;

  .Parents {
    justify-content: space-evenly;
    display: flex;
  }

  .Person {
    justify-content: space-evenly;
    display: flex;
  }

  .Children {
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`

const PersonalPage = ({ currentUser }) => {
  const { showSuccess, showWarning, showError } = useContext(MessageContext)
  const { id } = useParams()
  const [person, setPerson] = useState(null)
  const [peopleList, setPeopleList] = useState(null)

  const addFather = (id) => (
    updatePerson(person?.id, { father_id: id }).then((person) => {
      showSuccess('Бацька пасьпяхова дабаўлены')
      setPerson(person)
    })
  )

  const addMother = (id) => (
    updatePerson(person?.id, { mother_id: id }).then((person) => {
      showSuccess('Маці пасьпяхова дабаўлена')
      setPerson(person)
    })
  )

  const addChild = (id) => (
    updatePerson(id, { [person?.gender === 'man' ? 'father_id' : 'mother_id']: person?.id }).then((child) => {
      showSuccess(`Дзіцё пасьпяхова дабаўлена`)

      setPerson({
        ...person,
        children: [
          ...person.children, child
        ]
      })
    })
  )

  const filterFather = ({ attributes: { gender } }) => (gender === 'man')
  const filterMother = ({ attributes: { gender } }) => (gender !== 'man')

  const fatherModalSettings = {
    title: 'Бацька',
    addPerson: addFather,
    options: useMemo(() => peopleList?.filter(filterFather).map(peopleToSelectOptions), [peopleList]),
    newPersonLink: `/people/new?child_id=${person?.id}&redirect_path=%2Fhome%2F${person?.id}`
  }

  const motherModalSettings = {
    title: 'Маці',
    addPerson: addMother,
    options: useMemo(() => peopleList?.filter(filterMother).map(peopleToSelectOptions), [peopleList]),
    newPersonLink: `/people/new?child_id=${person?.id}&redirect_path=%2Fhome%2F${person?.id}`
  }

  const childModalSettings = {
    title: 'Дзіцё',
    addPerson: addChild,
    options: useMemo(() => peopleList?.map(peopleToSelectOptions), [peopleList]),
    newPersonLink: `/people/new?${person?.gender === 'man' ? 'father' : 'mother'}_id=${person?.id}&redirect_path=%2Fhome%2F${person?.id}`
  }

  const [modalSettings, setModalSettings] = useState(motherModalSettings)

  const setMotherModalSettings = () => {
    setModalSettings(motherModalSettings)
  }

  const setFatherModalSettings = () => {
    setModalSettings(fatherModalSettings)
  }

  const setChildModalSettings = () => {
    setModalSettings(childModalSettings)
  }

  const closeModalButtonRef = useRef(null)

  const sendRequest = useCallback((personId) => {
    console.log('personId', personId)
    const params = {
      person_id: personId,
      action: 'show'
    }

    createRequestPermission({ request_permission: params }).then((requestPermission) => {
      showSuccess('Запыт пасьпяхова адпраўлены')
      // requestPermission
    }).catch(() => {
      showError()
    })
    // {`/request_permissions/new?person_id=${data.subject.id}&request_action=show`}
  }, [])

  useEffect(() => {
    getPersonShort(id || currentUser.person_id).then(setPerson).catch((response) => {
      const data = response.response.data.data

      const subject = <Link to={`/people/${data.subject.id}`}>{data.subject.full_name}</Link>
      const author = <Link to={`/people/${data.subject.created_by_id}`}>аўтара</Link>
      const request = <button onClick={() => sendRequest(data.subject.id)}>тут</button>
      const message = <>У вас няма правоў на прагляд старонкі {subject}. Каб запрасіць правы ў {author} націсьніце {request}</>

      showWarning(message)
    })
  }, [id, currentUser.person_id, showWarning, sendRequest])

  useEffect(() => {
    getPeopleList().then(setPeopleList)
  }, [])

  const addPersonChange = (e) => {
    if (e === '') {
      return
    }

    modalSettings.addPerson(e.value).then(() => {
      closeModal()
    })
  }

  const closeModal = () => {
    closeModalButtonRef.current.click()
  }

  return (
    <Container>
      <div className='container col-md-6 col-sm-12'>
        <div className='row'>
          <PersonCard person={person?.father} onOpenModal={setFatherModalSettings}/>
          <PersonCard person={person?.mother} onOpenModal={setMotherModalSettings}/>
        </div>
        <div className='row'>
          <PersonCard person={person} path={`/people/${person?.id}`}/>
        </div>
        <div className='row'>
          {
            person?.children && [...person?.children].map((child, index) => {
              return (
                <PersonCard key={index} person={child}/>
              )
            })
          }
          <PersonCard onOpenModal={setChildModalSettings}/>
        </div>
      </div>

      <div aria-labelledby="myModalLabel" className="modal fade" id="modal-child" role="dialog" aria-modal="true" aria-hidden='true'>
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header d-block">
              <button ref={closeModalButtonRef} aria-label="Close" className="close float-right" data-dismiss="modal"
                type="button"><span aria-hidden="true">×</span></button>
              <button aria-label="Close" className="close float-left"
                data-dismiss="modal" type="button"><span aria-hidden="true">&nbsp;</span></button>
              <h5 className="modal-title text-center">{modalSettings.title}</h5>
            </div>
            <div className="modal-body text-center">
              <div className="actions">
                <div className="form-group select optional person_child_id">
                  <Select onChange={addPersonChange} placeholder="Выбраць..." options={modalSettings.options}/>
                </div>
                <hr/>
                <Link className="btn btn-default" onClick={closeModal} to={modalSettings.newPersonLink}>
                  Дадаць новага чалавека
                </Link>
              </div>
              <div className="spinner justify-content-center" style={{display: "none"}}>
                <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser,
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser)),
    setPreviousPath: (path) => dispatch(setPreviousPath(path)),
  })
)(PersonalPage);
