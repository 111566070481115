import { useState, useEffect, useRef, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import Loader from './Loader'

import { MessageContext } from 'App'
import { getPeopleList, updateDocument, deleteDocument } from 'api'
import { TextField, Textarea } from 'components'
import { formatInputDate, peopleToSelectOptions } from 'helpers/utils'

import 'lightgallery/scss/lightgallery.scss'
import 'lightgallery/scss/lg-thumbnail.scss'
import 'lightgallery/scss/lg-fullscreen.scss'
import 'lightgallery/scss/lg-transitions.scss'

const Container = styled('div')`
  .Documents {
    // display: flex;
    // flex-direction: column;
  }

  .Header {
    justify-content: space-between;
    display: flex;
  }

  .Title {
    gap: 8px;
    align-items: center;
    display: flex;
  }

  .People {
    gap: 2px;
    display: flex;
    flex-wrap: wrap;
  }

  .white {
    color: #fff;
  }
`

const DocumentExplorer = ({ documents, setDocuments }) => {
  const { showSuccess, showError } = useContext(MessageContext)
  const [isLoading, setIsLoading] = useState(false)
  const closeModalButtonRef = useRef(null)
  const addPersonSelectRef = useRef(null)
  const [peopleList, setPeopleList] = useState([])
  const [document, setDocument] = useState(null)
  const [selectedPeople, setSelectedPeople] = useState([])
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [date, setDate] = useState(null)

  const peopleOptions = useMemo(() => peopleList?.map(peopleToSelectOptions), [peopleList])

  useEffect(() => { getPeopleList().then(setPeopleList) }, [])

  const onDocumentDelete = (id) => {
    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць дакумент?`)) {
      deleteDocument(id).then((response) => {
        showSuccess('Дакумент пасьпяхова выдалены')

        setDocuments([
          ...documents.filter(({ id }) => id != response.id)
        ])
      }).catch((respose) => {
        showError()
      })
    }
  }

  const onChangePeopleSelected = (options, _) => {
    setSelectedPeople(options)
  }

  const onOpenModal = (documentId) => {
    const document = documents.find(({ attributes: { id }}) => id === documentId)

    setDocument(document)
    setName(document.attributes.name)
    setDescription(document.attributes.description)
    setDate(document.attributes.date)
    setSelectedPeople([...peopleOptions.filter(({ value }) => document.attributes.people.find(({ id }) => id == value))])
  }

  const closeModal = () => {
    closeModalButtonRef.current.click()
  }

  const onUpdate = () => {
    const params = { name, description, date, person_ids: selectedPeople.map(({ value }) => value) }
    const index = documents.indexOf(document)

    updateDocument(document?.id, { document: params }).then((document) => {
      showSuccess('Дакумент пасьпяхова абноўлены')

      setDocuments([
        ...documents.slice(0, index),
        { attributes: document },
        ...documents.slice(index + 1, documents.length)
      ])

      closeModal()
    }).catch((respose) => {
      showError()
    })
  }

  return (
    <Container className="card">
      <ul className="Documents list-group list-group-flush">
        {
          documents.map(({ attributes: { id, name, file: { url }, people }}) => {
            return (
              <li key={id} className="list-group-item">
                <div className='Header'>
                  <div className='Title'>
                    <i className="fa fa-file-archive-o"></i>
                    <a href={url}>
                      {name}
                    </a>
                  </div>
                  <div className="actions">
                    <a className="btn text-dark edit-document" data-toggle="modal" href="#modal-child" onClick={() => onOpenModal(id)}>
                      <i className="fa fa-pencil"></i>
                    </a>
                    <button className="btn" rel="nofollow" onClick={() => onDocumentDelete(id)}>
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="People">
                  {
                    people.map(({ id, full_name }) => {
                      return (
                        <span key={id} className="badge badge-secondary badge-pill">
                          <Link className="white" to={`/people/${id}`}>{full_name}</Link>
                        </span>
                      )
                    })
                  }
                </div>
              </li>
            )
          })
        }
      </ul>
      {isLoading && <Loader/>}

      <div aria-labelledby="myModalLabel" className="modal fade" id="modal-child" role="dialog" aria-modal="true" aria-hidden='true'>
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header d-block">
              <button ref={closeModalButtonRef} aria-label="Close" className="close float-right" data-dismiss="modal"
                type="button"><span aria-hidden="true">×</span></button>
              <button aria-label="Close" className="close float-left"
                data-dismiss="modal" type="button"><span aria-hidden="true">&nbsp;</span></button>
              <h5 className="modal-title text-center">Рэдагаваньне</h5>
            </div>
            <div className="modal-body text-center">
              <div className="actions">
                <TextField value={name || ''} onChange={(e) => setName(e.target.value)}/>
                <Textarea placeholder={'Апісаньне'} value={description || ''} onChange={(e) => setDescription(e.target.value)}/>
                <TextField placeholder={'Дата'} type='date' value={formatInputDate(date) || ''} onChange={(e) => setDate(e.target.value)}/>
                <div className="form-group select">
                  <ReactSelect
                    isMulti
                    placeholder="Людзі"
                    value={selectedPeople}
                    onChange={onChangePeopleSelected}
                    options={peopleOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
                <hr/>
                <Link className="btn btn-default" onClick={onUpdate}>
                  Захаваць
                </Link>
              </div>
              <div className="spinner justify-content-center" style={{display: "none"}}>
                <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default DocumentExplorer
