import React, { createContext } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Layout } from './components'

import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import './App.scss'

import { setCurrentUser } from 'store/actions'

export const MessageContext = createContext({ showSuccess: () => {}, showError: () => {}, showInfo: () => {}, showWarning: () => {}})

const TOAST_CONFIG = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light"
}

const App = () => {
  const showSuccess = (text = 'Посьпех') => toast.success(text, TOAST_CONFIG)
  const showError = (text = 'Памылка') => toast.error(text, TOAST_CONFIG)
  const showInfo = (text) => toast.info(text, TOAST_CONFIG)
  const showWarning = (text) => toast.warning(text, TOAST_CONFIG)

  const messageContextValue = { showInfo, showWarning, showSuccess, showError }

  return (
    <Router>
      <MessageContext.Provider value={messageContextValue}>
        <ToastContainer newestOnTop/>
        <Layout/>
      </MessageContext.Provider>
    </Router>
  )
}

export default connect(
  (state) => ({
    currentUser: state.main.currentUser
  }),
  (dispatch) => ({
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser))
  })
)(App);
