import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import 'helpers/plugins/imgAreaSelect/imgAreaSelect.css'
import cx from 'classnames'

import { MessageContext } from 'App'
import { getNotes, updateNote, deleteNote } from 'api'
import { formatDatetime } from 'helpers/utils'

const Container = styled.div`
  .Note-Status_hidden {
    text-decoration: line-through;
  }
`

const NotesPage = () => {
  const [notes, setNotes] = useState([])
  const { showSuccess, showError } = useContext(MessageContext)

  useEffect(() => { getNotes().then(setNotes) }, [])

  const onNoteToogle = (id) => {
    const note = notes.find(({ attributes: { id: noteId }}) => noteId === id )
    const index = notes.indexOf(note)
    const status = note.attributes.status

    const params = { note: { status: status === 'actual' ? 'hidden' : 'actual' }}

    updateNote(id, params).then((note) => {
      showSuccess(`Заўвага пасьпяхова ${status === 'actual' ? 'схавана' : 'павернута'}`)

      setNotes([
        ...notes.slice(0, index),
        { attributes: note },
        ...notes.slice(index + 1, notes.length)
      ])
    }).catch((respose) => {
      showError()
    })
  }

  const onNoteDelete = (id) => {
    if (window.confirm(`Вы ўпэўнены, што хочаце выдаліць нататку?`)) {
      deleteNote(id).then((response) => {
        showSuccess('Заўвага пасьпяхова выдалена')

        setNotes([...notes.filter(({ attributes: { id }}) => id != response.id)])
      }).catch((respose) => {
        showError()
      })
    }
  }

  return (
    <Container>
      <table className="table">
        <tbody>
          <tr>
            <th>Дата</th>
            <th>Імя</th>
            <th>Заўвагі</th>
            <th>Дзеяньні</th>
          </tr>
          {
            notes.map((note) => {
              const {
                attributes: {
                  id,
                  message,
                  created_at,
                  status,
                  from: { id: fromId, full_name: fromFullName },
                  to: { id: toId, full_name: toFullName }
                }
              } = note;

              return (
                <tr key={id} className={cx('Note', { [`Note-Status_${status}`]: true })}>
                  <td>{formatDatetime(created_at)}</td>
                  <td>ад <Link to={`/people/${fromId}`}>{fromFullName}</Link><br/>наконт <Link to={`/people/${toId}`}>{toFullName}</Link></td>
                  <td>{message}</td>
                  <td className="actions">
                    <button className="btn btn-default" onClick={() => onNoteToogle(id)}>
                      <i className={cx("fa", { 'fa-eye': status === 'actual', 'fa-eye-slash': status === 'hidden' })}></i>
                    </button>
                    <button className="btn btn-default" onClick={() => onNoteDelete(id)}>
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </Container>
  )
}

export default NotesPage
