import client from './client';
import Cookies from 'js-cookie';
import { API_URL, TOKEN } from 'constants'

export function getNote(id) {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/notes/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function getNotes() {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/notes.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function createNote(params) {
  const token = Cookies.get(TOKEN)

  return client.post(`${API_URL}/api/v1/notes.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function updateNote(id, params) {
  const token = Cookies.get(TOKEN)

  return client.put(`${API_URL}/api/v1/notes/${id}.json`, params, { headers: { Authorization: token } })
    .then(response => response.data.data)
}

export function deleteNote(id) {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/api/v1/notes/${id}.json`, { headers: { Authorization: token } })
    .then(response => response.data.data)
}
