import { useEffect, useState, useMemo, useContext, memo } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { MessageContext } from 'App'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .Header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .Progress-Wrapper {
    z-index: -1;
    width: 100%;
    border: 1px solid #0003;
    border-radius: 3px
  }

  .Progress {
    background-color: #0cf;
    height: 5px;
    transition: width 1s;

    &_Uploaded {
      background-color: #0e0;
    }

    &_Failed {
      background-color: #e00;
    }
  }
`

const FileUploader = ({ type, file, createFile, onUploaded }) => {
  const { showError } = useContext(MessageContext)
  const [loaded, setLoaded] = useState(0)
  const [total, setTotal] = useState(file.size)
  const [speed, setSpeed] = useState(0)
  const [uploaded, setUploaded] = useState(false)
  const [failed, setFailed] = useState(false)

  useEffect(() => {
    const formData = new FormData()
    formData.append(`${type}[file]`, file)

    let prevLoaded, prevTime = 0

    const config = {
      onUploadProgress: ({ loaded: nextLoaded, total }) => {
        const nextTime = Date.now()

        if (prevLoaded && prevTime) {
          setSpeed(((((nextLoaded - prevLoaded) / (1024*1024)) / ((nextTime - prevTime) / 1_000))).toFixed(2))
        }

        setLoaded(nextLoaded)
        setTotal(total)

        prevLoaded = nextLoaded
        prevTime = nextTime
      }
    }

    createFile(formData, config).then((data) => {
      setUploaded(true)

      return data
    }).then(onUploaded).catch(response => {
      showError()

      setFailed(true)
    })
  }, [type, file, createFile, onUploaded, showError])

  const progress = useMemo(() => ((loaded * 100) / total).toFixed(2), [total, loaded])
  const filesize = useMemo(() => ((file.size / (1024*1024)).toFixed(2)), [file])
  const description = useMemo(() => {
    if (uploaded) {
      return `Гатова`
    } else if (failed) {
      return 'Памылка'
    } else if (progress == 100.00) {
      return `Апрацоўка на серверы...`
    } else {
      return `Запампоўка ${speed}Мб/с (${progress}% з ${filesize}Мб)`
    }
  }, [speed, progress, filesize, uploaded, failed])

  return (
    <Container>
      <div className='Header'>
        <div>{file.name}</div>
        <div>{description}</div>
      </div>
      <div className='Progress-Wrapper'>
        <div className={cx('Progress', { 'Progress_Uploaded': uploaded, 'Progress_Failed': failed })} style={{ width: `${progress}%` }}></div>
      </div>
    </Container>
  )
}

export default memo(FileUploader)
