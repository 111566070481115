import { SET_CURRENT_USER, SET_PREVIOUS_PATH } from '../../constants';

export default (state = {}, action) => {
  switch(action.type) {
    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
      }
    }
    case SET_PREVIOUS_PATH: {
      return {
        ...state,
        previousPath: action.payload,
      }
    }
    default: {
      return state;
    }
  }
}
