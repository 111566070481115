/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useCallback } from 'react'
import LightGallery from 'lightgallery/react'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import fullScreen from 'lightgallery/plugins/fullscreen'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import 'lightgallery/scss/lightgallery.scss'
import 'lightgallery/scss/lg-thumbnail.scss'
import 'lightgallery/scss/lg-fullscreen.scss'
import 'lightgallery/scss/lg-transitions.scss'

const Container = styled('div')`
  td {
    a {
      padding: 0 5px;
    }
  }

  .img-responsive {
    cursor: pointer;
  }
`

const Photos = ({ photos }) => {
  const lgAfterSlide = useCallback(({ index }) => {
    const toolbar = document.getElementsByClassName('lg-toolbar')[0]
    let editButton = document.getElementById('lg-edit-button')

    if (!editButton) {
      const icon = document.createElement('i')
      icon.className = 'fa fa-pencil'

      const span = document.createElement('span')
      span.className = 'lg-icon'
      span.append(icon)

      editButton = Object.assign(document.createElement('a'), { id: 'lg-edit-button' })
      editButton.append(span)

      toolbar.append(editButton)
    }

    const photoId = document.getElementsByClassName('gallery-item')[index].dataset.id

    document.getElementById('lg-edit-button').setAttribute('href', `/photos/${photoId}/edit`)
  }, [])

  return (
    <Container>
      <LightGallery onAfterSlide={lgAfterSlide} toggleThumb={true} allowMediaOverlap={true} plugins={[lgThumbnail, fullScreen]} mode='lg-lollipop'>
        {
          photos.map(({ attributes: { id, file: { url, thumb_url, large_url } }}, index) => {
            return (
              <Fragment key={index}>
                <a key={id}
                  data-id={id}
                  data-src={large_url}
                  data-sub-html={`.photo-${id}`}
                  className='Photo gallery-item'
                  data-download-url={url}
                >
                  <img className="Image img-responsive" id={id} src={thumb_url} alt={id}/>
                </a>
              </Fragment>
            )
          })
        }
      </LightGallery>
      {/* {
        photos.map(({ attributes: { id, thumb_url, large_url, url, tags }}, index) => {
          return (
            <div key={id} className={`photo-${id}`} style={{ display: 'none' }}>
              {
                tags.map(({ id, title, person_id }) => {
                  return (
                    <Link key={id} to={`/people/${person_id}`} className='tag'>
                      {title}
                    </Link>
                  )
                })
              }
            </div>
          )
        })
      } */}
    </Container>
  )
}

export default Photos

