import client from './client';
import { API_URL, TOKEN } from 'constants'
import Cookies from 'js-cookie';

export const login = (params) => {
  return client.post(`${API_URL}/login`, params, { withCredentials: true })
    .then(response => {
      Cookies.set(TOKEN, response.headers.authorization)

      return response
    })
    .then(response => response.data)
    .catch((reason) => {
      console.log(reason)

      return {
        errors: [
          {
            message: reason.response.data
          }
        ]
      }
    })
}

export const signup = (params) => {
  return client.post(`${API_URL}/signup`, params, { withCredentials: true })
    .then(response => {
      Cookies.set(TOKEN, response.headers.authorization)

      return response
    })
    .then(response => response.data)
    .catch((reason) => {
      console.log(reason)

      return {
        errors: [
          {
            message: reason.response.data
          }
        ]
      }
    })
}

export const logout = () => {
  const token = Cookies.get(TOKEN)

  return client.delete(`${API_URL}/logout`, { headers: { Authorization: token } })
    .then(response => {
      Cookies.remove(TOKEN)

      return response
    })
    .then(response => response.data)
    .catch((reason) => {
      console.log(reason)

      return {
        errors: [
          {
            message: reason.response.data
          }
        ]
      }
    })
}

export function getCurrentUser() {
  const token = Cookies.get(TOKEN)

  return client.get(`${API_URL}/api/v1/current_user/index.json`, { headers: { Authorization: token } })
    .then(response => response.data)
    .catch((reason) => {
      console.log(reason)

      return {
        errors: [
          {
            message: reason.response.data.error
          }
        ]
      }
    })
}