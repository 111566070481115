import { useState, useContext } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { MessageContext } from 'App'
import { PeopleForm } from 'components'
import { createPerson } from 'api'

const Container = styled.div`
  max-width: 960px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

const PeopleNewPage = () => {
  const { showSuccess, showError } = useContext(MessageContext)
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const [person, setPerson] = useState({
    father_id: searchParams.get('father_id'),
    mother_id: searchParams.get('mother_id')
  })

  const onChangeByName = (name) => (e) => setPerson({ ...person, [name]: e.target?.value})

  const onCreate = () => {
    const formData = new FormData()
    const childId = searchParams.get('child_id')

    formData.append('person[last_name]', person?.last_name || '')
    formData.append('person[first_name]', person?.first_name || '')
    if (person?.born_date) formData.append('person[born]', person?.born_date)
    if (person?.died_date) formData.append('person[died]', person?.died_date)
    if (person?.gender) formData.append('person[gender]', person?.gender)
    if (person?.father_id) formData.append('person[father_id]', person?.father_id)
    if (person?.mother_id) formData.append('person[mother_id]', person?.mother_id)
    if (person?.location) formData.append('person[location]', person?.location)
    if (person?.comment) formData.append('person[comment]', person?.comment)
    if (childId) formData.append('person[child_id]', childId)
    if (person?.file) formData.append('person[file]', person?.file)

    createPerson(formData).then((person) => {
      showSuccess('Чалавек пасьпяхова дададзены')

      setPerson(person)

      navigate(searchParams.get('redirect_path') || '/people')
    }).catch((respose) => {
      showError()
    })
  }

  return (
    <Container>
      <h2>Дадаваньне</h2>
      <PeopleForm person={person} onChangeByName={onChangeByName} onSumbit={onCreate}/>
    </Container>
  )
}

export default PeopleNewPage
